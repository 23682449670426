//Install
import React, { Component } from "react";
//Install
import { ReactComponent as C0E427 } from "../../../Media/Icon/000.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    Y27JG3 = (e) => {
        //Element
        e.preventDefault()
        //Element
        const { Target } = this.props
        //Element
        window.location.href = Target
    }
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 n69z35", onClick: this.Y27JG3 },
            //Element
            React.createElement( C0E427, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;