//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    Counter = (Item, Duration, Start = 0) => {
        //Element
        let End   = Item.innerText.replaceAll( ".", "" ).trim()
        //Element
        let Range = End - Start
        //Element
        let Increment = ( Range / ( Duration / 2 ) )
        //Element
        if( End < Start ){
            //Element
            Increment *= -1
        }
        //Element
        var Current = Start
        //Element
        var Step    = 1
        //Element
        var Timer   = setInterval( function() {
            //Element
            Current += Math.ceil( Increment )
            //Element
            Item.innerText = Current
            //Element
            if( Current >= End ) {
                //Element
                Item.innerText = End
                //Element
                clearInterval( Timer )
            }
        }, Step )
    }
    //Element
    componentDidMount(){
        //Element
        document.querySelectorAll( ".g4q395" ).forEach( (Item) => {
            //Element
            this.Counter( Item, 60000 )
        })
    }
    //Element
    render() {
        //Element
        const { ID, Title, Number, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 bd974c" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ts420b" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 lh-1 g4q395" },
                    //Element
                    Number
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ts420b" },
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 m-0 mt-1 q328u4" },
                    //Element
                    Title
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Number : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;