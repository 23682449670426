//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/003.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-5 overflow-hidden g084ua", title: Title, href: Target },
            //Element
            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 overflow-hidden p836f5" } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 position-absolute start-0 bottom-0 p791q5" },
                //Element
                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 n8f41g" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 rounded-circle hg1856" },
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired,
}
//Element
export default Default;