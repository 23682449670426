//Install
import React, { Component } from "react";
//Install
import { ReactComponent as CF574T } from "../../Media/Social/001.svg";
//Install
import { ReactComponent as Y4945A } from "../../Media/Social/002.svg";
//Install
import { ReactComponent as SV202C } from "../../Media/Social/003.svg";
//Install
import { ReactComponent as YF879M } from "../../Media/Social/004.svg";
//Install
import { ReactComponent as WC85P2 } from "../../Media/Social/005.svg";
//Install
import propTypes from "prop-types";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const { ID, Facebook, X, Instagram, YouTube, Linkedin } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 j517vj" },
            //Element
            React.createElement( "li", { className: "float-start" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0", title: "Facebook", href: Facebook },
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-center rounded-circle r6817o" },
                        //Element
                        React.createElement( CF574T, {} )
                    )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", title: "X", href: X },
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-center rounded-circle iti966" },
                        //Element
                        React.createElement( Y4945A, {} )
                    )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", title: "Instagram", href: Instagram },
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-center rounded-circle iti966" },
                        //Element
                        React.createElement( SV202C, {} )
                    )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", title: "YouTube", href: YouTube },
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-center rounded-circle m2v884" },
                        //Element
                        React.createElement( YF879M, {} )
                    )
                )
            ),
            //Element
            React.createElement( "li", {},
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4", title: "Linkedin", href: Linkedin },
                    //Element
                    React.createElement( "span", { className: "float-start w-auto p-0 m-0 text-center rounded-circle iti966" },
                        //Element
                        React.createElement( WC85P2, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Social.propTypes = {
    ID        : propTypes.string.isRequired,
    Facebook  : propTypes.string.isRequired,
    X         : propTypes.string.isRequired,
    Instagram : propTypes.string.isRequired,
    YouTube   : propTypes.string.isRequired,
    Linkedin  : propTypes.string.isRequired
}
//Element
export default Social;