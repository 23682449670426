//Install
import React, { Component } from "react";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 ua449k" },
            //Element
            React.createElement( "menu", { className: "float-start w-100 p-0 m-0 h3u765" },
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/kurumsal" },
                        //Element
                        "Kurumsal"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/faaliyet-alanlari" },
                        //Element
                        "Sektörler"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/surdurulebilirlik" },
                        //Element
                        "Sürdürülebilirlik"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/kurumsal-yonetim" },
                        //Element
                        "Kurumsal Yönetim"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/medya-merkezi" },
                        //Element
                        "Haberler ve Bültenler"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/kariyer" },
                        //Element
                        "İnsan Kaynakları"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-3 m-0", href: "/iletisim" },
                        //Element
                        "İletişim"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Submenu;