//Install
import React, { Component } from "react";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "menu", { className: "d-flex w-50 justify-content-end align-items-center p-0 m-0 sn8447" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0", title: "Kişisel Verilerin Korunması", href: "/" },
                    //Element
                    "Kişisel Verilerin Korunması"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-3", title: "Kullanım Koşulları", href: "/" },
                    //Element
                    "Kullanım Koşulları"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-3", title: "Bilgi Toplumu Hizmetleri", href: "/" },
                    //Element
                    "Bilgi Toplumu Hizmetleri"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;