//Install
import React, { Component } from "react";
//Install
import { ReactComponent as YE33B6 } from "../../../Media/Icon/005.svg";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "form", { className: "float-start w-100 p-0 m-0 b51403", method: "GET" },
            //Element
            React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 x98d3z", type: "text", placeholder: "Arama...", required: "required" } ),
            //Element
            React.createElement( "button", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 m0a9d9", type: "submit" },
                //Element
                React.createElement( YE33B6, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;