//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as D09N69 } from "../../../Media/Icon/007.svg";
//Install
import { ReactComponent as SGU702 } from "../../../Media/Icon/008.svg";
//Install
import { ReactComponent as I5E716 } from "../../../Media/Icon/009.svg";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "İletişim | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/iletisim"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "İletişim | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/iletisim"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "İletişim | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    W65U06 = () => {
        //Element
        let U47K46 = document.getElementsByClassName( "iw862h" )[0]
        //Element
        let GPX789 = document.getElementsByClassName( "o8f41v" )[0]
        //Element
        U47K46.classList.remove( "mt-5" )
        //Element
        GPX789.classList.add( "border-0" )
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "İletişim | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/iletisim" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.W65U06()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
            //Element
            React.createElement( Breadcrumb, { ID: "0", Title: "İletişim", Sub: "Genel Bilgilendirme", Target: "/iletisim" } ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ev804z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m3h8a4" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 q27n1h" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d4dx67" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto" },
                                    //Element
                                    "Atasaylar Şirketler Grubu"
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 j57097" },
                                    //Element
                                    React.createElement( "i", { className: "float-start w-100 p-0 m-0 oq4v07" },
                                        //Element
                                        React.createElement( D09N69, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { className: "d-flex w-100 justify-content-between p-0 ps-4 m-0 position-absolute start-0 top-0 dv329l" },
                                        //Element
                                        "Telefon",
                                        //Element
                                        React.createElement( "br", {} ),
                                        //Element
                                        React.createElement( "strong", {},
                                            //Element
                                            "0880 880 88 00"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 j57097" },
                                    //Element
                                    React.createElement( "i", { className: "float-start w-100 p-0 m-0 v467vo" },
                                        //Element
                                        React.createElement( SGU702, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { className: "d-flex w-100 justify-content-between p-0 ps-4 m-0 position-absolute start-0 top-0 dv329l" },
                                        //Element
                                        "E-mail",
                                        //Element
                                        React.createElement( "br", {} ),
                                        //Element
                                        React.createElement( "strong", {},
                                            //Element
                                            "info@atasay.com"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 j57097" },
                                    //Element
                                    React.createElement( "i", { className: "float-start w-100 p-0 m-0 oq4v07" },
                                        //Element
                                        React.createElement( I5E716, {} )
                                    ),
                                    //Element
                                    React.createElement( "span", { className: "d-flex w-100 justify-content-between p-0 ps-4 m-0 position-absolute start-0 top-0 dv329l" },
                                        //Element
                                        "Konum",
                                        //Element
                                        React.createElement( "br", {} ),
                                        //Element
                                        React.createElement( "strong", {},
                                            //Element
                                            "Nakkaştepe, Azizbey Sokak No: 1, Kuzguncuk / İstanbul"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-4 pt-5 pb-5 m-0 mt-5 c121v9", method: "GET" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 r322d4" },
                                                //Element
                                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 ps-4 pe-4 m-0 x06q83", placeholder: "Ad", type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 r322d4" },
                                                //Element
                                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 ps-4 pe-4 m-0 x06q83", placeholder: "Soyad", type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 r322d4" },
                                                //Element
                                                React.createElement( "select", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 ps-4 m-0 s91j3n", required: "required" },
                                                    //Element
                                                    React.createElement( "option", { value: "0" },
                                                        //Element
                                                        "Firma"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "1" },
                                                        //Element
                                                        "İnşaat"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "2" },
                                                        //Element
                                                        "Turizm"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "3" },
                                                        //Element
                                                        "Gıda"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "4" },
                                                        //Element
                                                        "Endüstri"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "5" },
                                                        //Element
                                                        "Spor"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "6" },
                                                        //Element
                                                        "Teknoloji"
                                                    )
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 r322d4" },
                                                //Element
                                                React.createElement( "select", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 ps-4 m-0 s91j3n", required: "required" },
                                                    //Element
                                                    React.createElement( "option", { value: "0" },
                                                        //Element
                                                        "Konu"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "1" },
                                                        //Element
                                                        "Öneri"
                                                    ),
                                                    //Element
                                                    React.createElement( "option", { value: "2" },
                                                        //Element
                                                        "Bilgi Talebi"
                                                    )
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 r322d4" },
                                                //Element
                                                React.createElement( "textarea", { id: "Web_", name: "Web_", className: "float-start w-100 p-4 pt-3 m-0 x09717", placeholder: "Mesaj", rows: "5", cols: "20", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 r322d4" },
                                                //Element
                                                React.createElement( "button", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 xb675r", type: "submit" },
                                                    //Element
                                                    "Hemen Gönder"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 g2296t" },
                //Element
                React.createElement( "iframe", { className: "float-start w-100 border-0", src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12038.79376362733!2d29.038486!3d41.031853!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7e71772f349%3A0x6d2b9330a5100935!2zS2_DpyBIb2xkaW5nIEEuxZ4u!5e0!3m2!1str!2str!4v1727869414027!5m2!1str!2str", width: "600", height: "450" } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;