//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../../../Media/Icon/003.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Media, Target } = this.props
        //Element
        let Source = "https://cdn.benozgurtosun.com/customer/001/"
        //Element
        let Photo  = Source + Media
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 overflow-hidden ip2u99", title: Title, href: Target },
            //Element
            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 overflow-hidden h2389b", style: { backgroundImage: "url("+ Photo +")" } } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 position-absolute start-0 bottom-0 g476e4" },
                //Element
                React.createElement( "h3", { className: "float-start w-auto p-0 m-0 q04r2m" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-4 rounded-circle koh169" },
                    //Element
                    React.createElement( Icon, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Media  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;