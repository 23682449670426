//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Corporation from "./Component/Tab/Corporation/Showcase";
//Install
import Sector from "./Component/Tab/Sector/Showcase";
//Install
import Responsibility from "./Component/Tab/Responsibility/Showcase";
//Install
import Career from "./Component/Tab/Career/Showcase";
//Install
import News from "./Component/Tab/News/Default";
//Install
import Sustainability from "./Component/Tab/Sustainability/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/faaliyet-alanlari" element={ <Sector /> }></Route>
          <Route path="/surdurulebilirlik" element={ <Sustainability /> }></Route>
          <Route path="/kurumsal-yonetim" element={ <Responsibility /> }></Route>
          <Route path="/medya-merkezi" element={ <News /> }></Route>
          <Route path="/kariyer" element={ <Career /> }></Route>
          <Route path="/iletisim" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
