//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Sürdürülebilirlik | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        T00A3U.content = ""
        //Element
        HU221M.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://atasaylar.com.tr/surdurulebilirlik"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Sürdürülebilirlik | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        D68001.content = ""
        //Element
        N94MJ9.content = "https://atasaylar.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://atasaylar.com.tr/surdurulebilirlik"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Sürdürülebilirlik | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        W2LB44.content = ""
        //Element
        K08577.content = "https://atasaylar.com.tr/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Sürdürülebilirlik | Atasaylar Şirketler Grubu - Atasaylar.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = ""
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://atasaylar.com.tr/surdurulebilirlik" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 dl4n30" },
            //Element
            React.createElement( Breadcrumb, { ID: "0", Title: "Sürdürülebilirlik", Sub: "Genel Bilgilendirme", Target: "/surdurulebilirlik" } ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ev804z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m3h8a4" },
                                //Element
                                React.createElement( "figure", { className: "float-start w-100 p-0 m-0 b58910" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 d4dx67" },
                                //Element
                                React.createElement( "h1", {},
                                    //Element
                                    "Sürdürülebilirlik"
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Ataysalar Şirketler Grubu olarak inşaat, turizm, gıda, endüstri, spor ve teknoloji gibi çeşitli sektörlerde faaliyet gösterirken, sürdürülebilirliği iş stratejimizin merkezine yerleştirmiş bulunuyoruz."
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Gelecek nesillere yaşanabilir bir dünya bırakma sorumluluğuyla hareket ediyor ve tüm iş süreçlerimizde çevre dostu ve sosyal açıdan duyarlı yaklaşımlar benimsiyoruz."
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Çevresel Sürdürülebilirlik: Doğal kaynakların korunması, karbon ayak izimizin azaltılması ve yenilenebilir enerji kaynaklarına yatırım, tüm iş kollarımızda öncelikli hedeflerimizdendir."
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "İnşaat sektöründe enerji verimli yapılar ve geri dönüştürülebilir malzemeler kullanırken, turizm sektöründe çevreye zarar vermeyen ve yerel ekosistemleri destekleyen projelere odaklanıyoruz. Gıda sektöründe ise organik üretim, su tasarrufu ve sıfır atık politikalarını benimsiyoruz."
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Sosyal Sürdürülebilirlik: Topluma katkı sağlamak ve sosyal sorumluluk projelerinde aktif rol almak, Ataysalar’ın vazgeçilmez değerlerindendir. Çalışanlarımızın gelişimini destekleyen eğitim programları, yerel istihdamı artıran projeler ve toplumsal cinsiyet eşitliği ilkeleri doğrultusunda fırsat eşitliği sağlamak öncelikli hedeflerimiz arasında yer alır."
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Ekonomik Sürdürülebilirlik: Stratejik yatırımlarımızla, faaliyet gösterdiğimiz tüm sektörlerde istikrarlı büyümeyi sürdürmeyi ve yerel ekonomilere değer katmayı hedefliyoruz. Teknoloji ve sanayi alanındaki projelerimizle yenilikçi çözümler geliştirerek hem ekonomiye katkı sağlıyor hem de sürdürülebilir iş modelleriyle geleceğe kalıcı değerler bırakıyoruz."
                                ),
                                //Element
                                React.createElement( "p", {},
                                    //Element
                                    "Ataysalar Şirketler Grubu olarak, daha yeşil bir dünya ve daha güçlü topluluklar için sürdürülebilir çözümler üretmeye devam edeceğiz."
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;