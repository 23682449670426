//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Menu from "./Footer/Menu";
//Install
import Social from "./Footer/Social";
//Install
import Submenu from "./Footer/Submenu";
//Install
import Copyright from "./Footer/Copyright";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "float-start w-100 p-0 m-0 w71b4c" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 iw862h" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Social, { ID: "0", Facebook: "", X: "", Instagram: "", YouTube: "", Linkedin: "" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 k504o1" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 bt16w5" },
                                //Element
                                React.createElement( Copyright, { ID: "0", Title: "© 2024 Atasaylar Şirketler Grubu A.Ş. Tüm Hakları Saklıdır." } ),
                                //Element
                                React.createElement( Menu, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 position-fixed d-none s6679h" },
                //Element
                React.createElement( Submenu, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;