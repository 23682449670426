//Install
import React, { Component } from "react";
//Install
import { ReactComponent as I033M6 } from "../../../Media/Icon/002.svg";

//Element
class Default extends Component {
    //Element
    P741OH = (e) => {
        //Element
        e.preventDefault()
        //Element
        let X14DP5 = document.getElementsByClassName( "ot8t35" )[0]
        //Element
        X14DP5.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "float-end w-100 p-0 m-0 u33x9i", type: "button", onClick: this.P741OH },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 q8mt23" },
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0" },
                    //Element
                    React.createElement( I033M6, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;