//Install
import React, { Component } from "react";
//Install
import { ReactComponent as DO047I } from "../../../Media/Icon/010.svg";
//Install
import { ReactComponent as PO035V } from "../../../Media/Icon/011.svg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        const { Page } = this.props
        //Element
        this.state = {
            //Element
            C5R6K3 : Page
        }
    }
    //Element
    L87T96 = (e) => {
        //Element
        e.preventDefault()
        //Element
        const { Page } = this.props
        //Element
        let G7E0P5 = document.querySelectorAll( ".q03e4u .n20oe4" )
        //Element
        let F13547 = this.state.C5R6K3
        //Element
        let T5P1V5 = parseInt(F13547) + parseInt(Page)
        //Element
        for (let Index = 0; Index < G7E0P5.length; Index++){
            //Element
            G7E0P5[Index].classList.add( "d-none" )
        }
        //Element
        if( T5P1V5 != G7E0P5.length ){
            //Element
            if( F13547 <= G7E0P5.length ){
                //Element
                for (let Index = F13547; Index < T5P1V5; Index++){
                    //Element
                    if( G7E0P5[Index] != undefined ){
                        //Element
                        G7E0P5[Index].classList.remove( "d-none" )
                    }
                }
            } else {
                //Element
                document.getElementById( "Web_N4U51A" ).disabled = true
                //Element
                for (let Index = 0; Index < Page; Index++){
                    //Element
                    G7E0P5[Index].classList.remove( "d-none" )
                }
            }
        }
        //Element
        this.setState({
            //Element
            C5R6K3 : T5P1V5
        })
    }
    //Element
    Z875A1 = (e) => {
        //Element
        e.preventDefault()
        //Element
        const { Page } = this.props
        //Element
        let G7E0P5 = document.querySelectorAll( ".q03e4u .n20oe4" )
        //Element
        let F13547 = this.state.C5R6K3
        //Element
        let T5P1V5 = parseInt(F13547) - parseInt(Page)
        //Element
        for (let Index = 0; Index < G7E0P5.length; Index++){
            //Element
            G7E0P5[Index].classList.add( "d-none" )
        }
        //Element
        if( T5P1V5 != G7E0P5.length ){
            //Element
            if( F13547 <= G7E0P5.length ){
                //Element
                for (let Index = F13547; Index < T5P1V5; Index++){
                    //Element
                    if( G7E0P5[Index] != undefined ){
                        //Element
                        G7E0P5[Index].classList.remove( "d-none" )
                    }
                }
            } else {
                //Element
                document.getElementById( "Web_T49554" ).disabled = true
                //Element
                for (let Index = 0; Index < Page; Index++){
                    //Element
                    G7E0P5[Index].classList.remove( "d-none" )
                }
            }
        }
        //Element
        this.setState({
            //Element
            C5R6K3 : T5P1V5
        })
    }
    //Element
    componentDidMount(){
        //Element
        const { Page } = this.props
        //Element
        let G7E0P5 = document.querySelectorAll( ".q03e4u .n20oe4" )
        //Element
        let G2F565 = G7E0P5.length / Page
        //Element
        for (let Index = 0; Index < Page; Index++){
            //Element
            G7E0P5[Index].classList.remove( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 mt-3 xb785y" },
            //Element
            React.createElement( "button", { id: "Web_T49554", name: "Web_T49554", className: "float-start w-100 p-0 m-0 mt-4 rounded-circle x9ej21", type: "button", onClick: this.Z875A1 },
                //Element
                React.createElement( DO047I, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_N4U51A", name: "Web_N4U51A", className: "float-start w-100 p-0 m-0 mt-4 rounded-circle z192j7", type: "button", onClick: this.L87T96 },
                //Element
                React.createElement( PO035V, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID   : propTypes.string.isRequired,
    Page : propTypes.string.isRequired
}
//Element
export default Default;