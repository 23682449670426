//Install
import React, { Component } from "react";
//Install
import { ReactComponent as A560F6 } from "../../../Media/Icon/001.svg";
//Install
import { ReactComponent as BJ9745 } from "../../../Media/Icon/004.svg";

//Element
class Toggle extends Component {
    //Element
    G896UT = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Y26W8J = document.getElementsByClassName( "r26h6n" )[0]
        //Element
        let K77Y56 = document.getElementsByClassName( "k441ug" )[0]
        //Element
        let G2E01M = document.getElementsByClassName( "s6679h" )[0]
        //Element
        let H64I72 = document.getElementsByTagName( "body" )[0]
        //Element
        Y26W8J.classList.toggle( "d-none" )
        //Element
        K77Y56.classList.toggle( "d-none" )
        //Element
        G2E01M.classList.toggle( "d-none" )
        //Element
        H64I72.classList.toggle( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "float-end w-100 p-0 m-0 u7658y", type: "button", onClick: this.G896UT },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 fyk501" },
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0 r26h6n" },
                    //Element
                    React.createElement( A560F6, {} )
                ),
                //Element
                React.createElement( "i", { className: "float-start w-auto p-0 m-0 d-none k441ug" },
                    //Element
                    React.createElement( BJ9745, {} )
                ),
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2 q2x026" },
                    //Element
                    "Menü"
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Toggle;