//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Image from "../../../Media/Image/002.jpg";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    F22P1A = () => {
        //Element
        const { Target } = this.props
        //Element
        window.location.href = Target
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Media, Target } = this.props
        //Element
        const Content = React.createElement( Container, {},
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 pe-5 m-0 mt-5 b7j658" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-auto p-0 ps-5 pe-5 m-0 v8v42g" },
                            //Element
                            "Beraber",
                            //Element
                            React.createElement( "br", {} ),
                            //Element
                            "Doğanın Geleceği İçin Çalışmak"
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-auto p-0 ps-5 pe-4 m-0 mt-3 q9xy48" },
                            //Element
                            "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir."
                        ),
                        //Element
                        React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 ms-5 an6468", type: "button", onClick: this.F22P1A },
                            //Element
                            "Sürdürülebilirlik"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-5 r0v40p" },
                        //Element
                        React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 y3a18f" },
                            //Element
                            React.createElement( "img", { alt: Title, title: Title, src: Image, width: "636", height: "auto" } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Media   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Default;