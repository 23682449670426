//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import News from "./Content/News/Default";
//Install
import Sector from "./Content/Sector/Default";
//Install
import Career from "./Content/Career/Default";
//Install
import Counter from "./Content/Counter/Default";
//Install
import Governance from "./Content/Governance/Default";
//Install
import Corporation from "./Content/Corporation/Default";
//Install
import Sustainability from "./Content/Sustainability/Default";

//Element
class Main extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 h3947e" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 og976r" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Counter, { ID: "0", Title: "Farklı Sektör'de", Number: "08", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Counter, { ID: "0", Title: "Güçlü Şirket", Number: "10", Target: "/" } )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Counter, { ID: "0", Title: "Lider Marka", Number: "08", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 me222n" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 i90wo5" },
                    //Element
                    React.createElement( Corporation, { ID: "0", Title: "Atasaylar Grubu'nu Tanıyın", Summary: "", Target: "/kurumsal" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 i90wo5" },
                    //Element
                    React.createElement( Sector, { ID: "0", Title: "Sektörler", Summary: "", Target: "/faaliyet-alanlari" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d7n8s0" },
                    //Element
                    React.createElement( Career, { ID: "0", Title: "Kariyer Fırsatları", Summary: "", Target: "/kariyer" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d7n8s0" },
                    //Element
                    React.createElement( Governance, { ID: "0", Title: "Kurumsal Yönetim", Summary: "", Target: "/kurumsal-yonetim" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d7n8s0" },
                    //Element
                    React.createElement( News, { ID: "0", Title: "Basın Odası", Summary: "", Target: "/medya-merkezi" } )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 rv3679" },
                //Element
                React.createElement( Sustainability, { ID: "0", Title: "Beraber Doğanın Geleceği İçin Çalışmak", Summary: "Daha Kaliteli Bir Gelecek, Hâlâ Elde Edilebilir.", Media: "", Target: "/surdurulebilirlik" } )
            )
        )   
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;