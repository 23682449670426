//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Form from "./Header/Form/Default";
//Install
import Brand from "./Header/Brand/Default";
//Install
import Toggle from "./Header/Toggle/Default";
//Install
import Search from "./Header/Search/Default";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "header", { className: "float-start w-100 p-0 m-0 position-relative g1r31t" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n5299z" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Brand, { ID: "0", Title: "Atasaylar Şirketler Grubu", Target: "/" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 o8f41v" },
                //Element
                React.createElement( Toggle, {} ),
                //Element
                React.createElement( Search, {} ),
                //Element
                React.createElement( "button", { className: "float-end w-100 p-0 m-0 u33x9i", type: "button" },
                    //Element
                    "EN"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none ot8t35" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Form, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;