//Install
import React, { Component } from "react";

//Element
class Safety extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 ps-5 pe-5 m-0 q18m0p" },
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 rl900u" },
                //Element
                "Atasaylar Şirketler Topluluğu, 10 binden fazla çalışanıyla, yüksek standartlarda hizmet sunmayı, yenilikçi düşünce yapısını benimsemeyi ve müşteri memnuniyetini en ön planda tutmayı bir araya getirerek güçlü bir sinerji yaratmaktadır."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 rl900u" },
                //Element
                "Atasaylar Şirketler Grubu'nun bu yaklaşımı, hem sektördeki konumunu sağlamlaştırmakta hem de çalışanlarının potansiyelini en üst düzeyde kullanmasına olanak tanımaktadır."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 rl900u" },
                //Element
                "Yenilikçi çözümler geliştirme, kaliteli hizmet sunma misyonuyla müşterilerinin ihtiyaçlarına hızla cevap veren dinamik yapısını sürekli olarak geliştirmeye devam etmektedir."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Safety;